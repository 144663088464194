@import'./variables.scss';
@font-face {
    font-family: "mini-sans-serif-bold";
    src: url('../fonts/mini-fonts/mini_sans_serif-bold-webfont.woff2');
}
@font-face {
    font-family: "mini-sans-serif-regular";
    src: url('../fonts/mini-fonts/mini_sans_serif-regular-webfont.woff2');
}
@font-face {
    font-family: "mini-serif-bold";
    src: url('../fonts/mini-fonts/mini_serif-bold-webfont.woff2');
}
@font-face {
    font-family: "mini-serif-regular";
    src: url('../fonts/mini-fonts/mini_serif-regular-webfont.woff2');
}
@font-face{
    font-family: 'BMWIconLibrary';
    src: url('../fonts/bmw-fonts/BMWIconLibrary.ttf');
}
@font-face{
    font-family: 'MaterialIconLibrary';
    src: url('../fonts/MaterialIcons-Regular.ttf');
}

// Fonts
$miniSansSerifBold: "mini-sans-serif-bold";
$miniSansSerifRegular: "mini-sans-serif-regular";
$miniSansSerif: mini-sans-serif, sans-serif;
$miniSerifBold: "mini-serif-bold";
$miniSerifRegular: "mini-serif-regular";
$miniSerif: "mini-serif",serif;
$bmwIcon: "BMWIconLibrary";
$materialIcon: "MaterialIconLibrary";