@import './bundle.scss';
.mini-theme{
    display: flex;
    flex-direction: column;
    height: 100vh;
    & .container{
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
    }
    & .content-container{
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
    }
    & .action-buttons{
        display: flex;
        border-top: 1px solid var(--input-border-gray);
        padding: 20px 0;
        justify-content: flex-end;
    }
    & .fs-button{
        &-btn{
            background-color: var(--mini-red) !important;
            border: 2px solid var(--mini-red)!important;
            color : var(--white) !important;
            font-family: $miniSansSerifRegular !important;
            border-radius: 0;
            font-size: 16px;
            line-height: 16px;
            font-weight: 400;
            text-transform: uppercase;
            padding: 15px 30px !important;
            opacity: 1;
            cursor: pointer;
            &:hover{
                background-color: var(--white) !important;
                border: 2px solid var(--black) !important;
                color: var(--dark) !important;
            }
            &-disabled{
                cursor: not-allowed;
                opacity: .5;
                &:hover{
                    background-color: var(--mini-red) !important;
                    border: 2px solid var(--mini-red)!important;
                    color : var(--white) !important;
                }
            }
            &-secondary{
                background-color: var(--white) !important;
                border: 2px solid var(--black) !important;
                color: var(--dark) !important;
                padding: 15px 30px !important;

                &:hover{
                    color: var(--white) !important;
                    background-color: var(--black) !important;
                }
            }
        }
    }
    & .fs-print-page {
        & .print-page-header{
            & svg{
                fill: var(--mini-red);
            }
        }
    }
    & .mat-mdc-checkbox label{
        font-family: $miniSansSerifRegular;
        font-size: $font-size-13;
    }
    & .fs-anchor{
        font-family: $miniSansSerifRegular !important;
    } 
    & .faq{
        & .faq-container{
            padding: 0 20px;
            width: auto !important;
            @media(max-width: $medium){
                padding: 0;
            }
            & .faq-content{
                background-color: var(--white) !important;
                padding: 50px !important;
                flex-basis: calc(66% - 30px) !important;
                margin-bottom: 60px;
                @media(max-width: $xlarge){
                    flex-basis: calc(64% - 30px) !important;
                }
                @media(max-width: $medium){
                    padding: 0 !important;
                }
                & h1.title{
                    padding-bottom: 0!important;
                    margin: 0 0 20px!important;
                    font-family: $miniSerifRegular;
                    font-size: 30px !important;
                    font-weight: 400 !important;
                    border-bottom: 0 !important;
                    @media(max-width: $medium){
                        padding: 30px 20px 0;
                        
                    }
                    &::after{
                        content: "";
                        display: block;
                        margin: 0;
                        left: 0;
                        width: 50px;
                        padding-top: 20px;
                        border-bottom: 2px solid var(--dark)
                    }
                }
                & .expansionPanel .mat-expansion-panel{
                    & .mat-expansion-panel-header{
                        &.cdk-keyboard-focused{
                            & .mat-expansion-panel-header-title{
                            color: var(--faq-link-hover) !important;
                            }
                        }
                        @media(max-width:$medium){
                            padding-right: 12px;
                            padding-left: 24px;
                        }
                        &-title{
                            font-family: $helvetica !important;
                            font-size: 16px !important;
                            font-weight: normal;
                            margin-right: 10px !important;
                            &:hover{
                                color: var(--faq-link-hover) !important;
                            }

                        }
                        &.mat-expanded {
                            &.mat-expansion-panel-header{
                                &.cdk-keyboard-focused{
                                    background: rgb(var(--shadow-color), var(--opacity004)) !important;
                                    color: rgb(var(--primary)) !important;
                                    & .mat-expansion-panel-header-title{
                                        color: rgb(var(--primary)) !important;
                                    }
                                }
                            }
                            & .mat-expansion-panel-header{
                                &-title{
                                    font-family: $helvetica !important;
                                    font-size: 16px !important;
                                    font-weight: bold;
                                    &:hover{
                                        color: rgb(var(--primary)) !important;
                                    }
                                }
                            }
                            & .mat-expansion-panel-content{
                                padding: 0 24px 16px 0;
                            }
                        }

                    }
                    & .mat-expansion-panel-body{
                        font-size: 12px;
                        font-family: $helvetica;
                         @media(max-width:$medium){
                            padding: 0 24px 24px !important;
                            font-size: 14px;
                         }
                    }
                    & .mat-expansion-indicator{
                        &:after{
                            font-size: 16px;
                        }
                    }
                    & .mat-expansion-indicator{
                        margin: 0;
                        left: -10px;
                    }
                    
                }
                
            }
            
        }
        
    }
    & .fs{
        &-sidenav{
          & ul {
            & li{
                background-color: var(--white) !important;
                border-radius: 0 !important;
                text-transform: uppercase;
                font-family: $helvetica;
                font-size: 11px !important;
                font-weight: 700 !important;
                position: relative;
                &:hover{
                    background-color: var(--profile-light-gray) !important;
                }
                &.active{
                    background-color: var(--black) !important;
                    cursor: default;
                    & a{
                        &:after{
                            content: "";
                            border-color: var(--white);
                            border-style: solid;
                            border-width: 1px 1px 0 0;
                            display: inline-block;
                            position: absolute;
                            transform: rotate(45deg);
                            vertical-align: top;
                            width: 8px;
                            height: 8px;
                            right: 14px;
                            top: 13px;
                        }
                    }
                    &:after{
                        display: none
                    }
                }
                & a{
                    &:after{
                        content: "";
                        border-color: var(--black);
                        border-style: solid;
                        border-width: 1px 1px 0 0;
                        display: inline-block;
                        position: absolute;
                        transform: rotate(45deg);
                        vertical-align: top;
                        width: 8px;
                        height: 8px;
                        right: 14px;
                        top: 13px;
                        @media screen and (max-width:$screen-medium) {
                            display: none;
                        }
                    }
                }
            }
          }
        }
        &-nav-mobile{
            padding: 0 20px;
            margin-bottom: 20px;
            &  .mat-select-placeholder{
                color: var(--white);
                font-size: 11px;
                padding-left: 15px!important;
                text-transform: uppercase;
            }
        }
    }
    & .mini-footer{
        border-top: 2px solid var(--light-gray);
        padding: 15px 0px;
        height: auto;
        @media(max-width: $minixmedium){
            padding: 20px 0;
        }
        & footer{
            padding: 0;
            border: 0px ;
        }
        & nav{
            width: 100%;
            padding: 0 20px;
            @media(max-width: $minixmedium){
                padding: 0 10px;
            }
        }
        & .container{
            text-align: left;
            width: 100%;
            & .copyright{
                font-size: 16px;
                font-weight: 400;
                color: rgb(var(--shadow-color));
                margin-bottom: 20px;
                margin-top: 0;
                font-family: $miniSansSerifRegular;
                &.desktop{
                    @media(max-width: $large){
                        display: none;
                    }
                }
                &.mobile{
                    @media(max-width: $large){
                        display: block;
                    }
                    @media(max-width: $minixmedium){
                        font-size: 14px;
                    }
                }
            }
            & ul{
                text-align: left;
                margin: 0;
                @media(max-width: $minixmedium){
                    display: flex;
                    flex-direction: column;
                }
                & li{
                    padding-right: 20px;
                    @media(max-width: $minixmedium){
                        margin-bottom: 20px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                    &.active{
                        & a{
                            font-weight: bold !important;
                        }
                    }
                    & a{
                        color: rgb(var(--shadow-color));
                        font-size: 16px;
                        font-weight: 400;
                        font-family: $miniSansSerifRegular;
                        cursor: pointer;
                        @media(max-width: $minixmedium){
                            font-size: 14px;
                        }
                    }
                    &:after{
                        display: none;
                    }
                }
            }
        }
        
    }
}
header .nav-secondary .desktop-container {
    .nav {
        .btn-account-dropdown {
            .mdc-button {
                .mdc-button__label {
                    span {
                        letter-spacing: normal;
                    }
                }
            }
        }
    }
}