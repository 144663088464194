@import './variables.scss';
body {
    margin:0;
    font-family: $miniSansSerif;
    background: var(--container-background);
}

h1{
    margin-block-start: 0;
}

.terms{
    &-accept{
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        #termsContent {
            & h1 {
                display: none;
            }
        }
        .page-terms-and-conditions .terms-and-conditions-container {
            @media (max-width : $mobile){
                padding-top:0
            }
            @media (max-width : $xsmall){
                padding-top:0
            }
            @media(max-width: $small){
                padding-top:0;
            }
        }
        .page-terms-and-conditions {
            @media (max-width : $mobile){
                padding-top:0
            }
            @media (max-width : $xsmall){
                padding-top:0
            }
            @media(max-width: $small){
                padding-top:0;
            }
        }    
    }
}

#sessionTimeOutDialog {
    padding: 40px 30px 20px !important;
    @media(max-width: $mobile) {
      max-width: 300px !important;
    }
    &.dialog-container{
      .fs-text.title{
        margin-top: 0;
        padding-top: 10px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 25px;
        line-height: 30px;
        color: var(--dark);
        font-family: $miniSerifBold;
        margin-bottom: 20px;
      }
      .dialog-bodytext{
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 20px;
        color: var(--dark);
        font-family: $miniSerifRegular;
        @media(max-width: $xmedium){
            font-size: 12px;
            line-height: 18px;
        }
      }
    }
    .dialog-buttonmultiple {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-top: 20px;
        @media( max-width: $minismall){
            flex-direction: column-reverse;
        }
        .dialog-button{
            width: auto;
            display: inline-block;
            margin: 0;
            align-self: flex-end;
            @media( max-width: $minismall){
                width: 100%
            }
            .fs-button-btn{
                border-radius: 0;
                font-size: 16px;
                line-height: 16px;
                font-weight: normal;
                text-transform: uppercase;
                padding: 15px 30px;
                border: 1px solid var(--mini-red) !important;
                opacity: 1;
                cursor: pointer;
                background: var(--mini-red);
                margin: 20px 0 0 10px !important;
                display: flex;
                width: auto;
                font-family: $miniSansSerifRegular;
                &:hover{
                    background: var(--white) !important;
                    border: 1px solid rgb(var(--shadow-color)) !important;
                    color: rgb(var(--shadow-color)) !important;
                }
                &-secondary{
                    background: var(--white) !important;
                    border: 1px solid rgb(var(--shadow-color)) !important;
                    color: var(--dark) !important;
                    padding: 15px 30px !important;
                    &:hover{
                        background-color: rgb(var(--shadow-color));
                        color: var(--white);
                        border: 1px solid rgb(var(--shadow-color)) !important;
                    }
                }
                @media( max-width: $minismall){
                    width: 100%;
                    justify-content: center;
                    margin: 10px 0 0 0 !important;
                }
            }
        }     
    }
  }

  @media (max-width: $xlarge) {
    .page-terms-and-conditions .terms-and-conditions-container {
        padding-left: 20px;
        padding-right: 20px;
    }
  }
  
 .print-page-html-content .page-terms-and-conditions .terms-and-conditions-container {
    max-width: 1100px;
    width: 100%;
    margin: auto;
    position: relative;
    max-width: 900px;
  }
  
  #termsContent{
    h1 {
      font-size: var(--font-size-35);
      font-weight: var(--font-weight-300);
      text-transform: uppercase;
      color: var(--dark);
      margin-bottom: 10px;
    }
  
    h2 {
      font-size: var(--font-size-15);
      font-weight: var(--font-weight-bold);
    }
  
    h3 {
      font-size: var(--font-size-20);
      font-weight: var(--font-weight-normal);
      text-transform: none;
    }
  
    h4 {
      font-size: var(--font-size-14);
      color: var(--dark);
      line-height: 24px;
      font-weight: var(--font-weight-400);
      margin-bottom: 20px;
    }
  
    .terms-header-section{
      padding-bottom: 40px;
      div{
        font-size: var(--font-size-14);
        padding-top: 5px;
      }
    }
  
    .terms-organization, .terms-section-para, .terms-contact, .terms-footer{
      font-size: var(--font-size-15);
      line-height: 18px;
      color: var(--dark);
      font-weight: 400;
    }
  
    .terms-section-para{
  
      padding-bottom: 10px;
    }
  
    .terms-section-header{
      padding-bottom: 10px;
      padding-top: 25px;
    }
  
    
  
    ol {
      margin-bottom: 0.2in;
    }
  
    ol li {
      font-size: var(--font-size-14);
      line-height: 21px;
      color: var(--dark);
      padding-left: 5px !important;
    }
  
    ol.lettered li {
      list-style-type: lower-alpha;
      
    }
  
    ol.romanletter li {
      list-style-type: lower-roman;
    }
  
    ol.lettered{
      padding-left: 25px;
    }
  
    ol.romanletter{
      padding-left: 25px;
    }
  
    .terms-footer{
      padding-bottom: 50px;
    }
  
    .terms-contact{
      padding-bottom: 15px;
    }
    
  }